<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col>
        <el-breadcrumb>
          <el-breadcrumb-item class="pointer" :to="{ path: '/streetServerType' }">导航</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li class="menu-item text-cut" v-for="(item, i) in tabs" :key="item"
                :class="seeThis == i ? 'active' : ''">
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip class="item" effect="dark" content="保存基本信息和扩展属性" placement="bottom">
                          <el-button type="primary" size="small" @click="submitForm('streetServerType')">保存</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="返回列表" placement="bottom">
                          <el-button @click="goIndex()" size="small">返回</el-button>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form :model="streetServerType" ref="streetServerType" label-width="150px" class="streetServerType"
                  size="mini">
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="streetServerType.id"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col v-if="judgeStreetAdmin()" :sm="24" :md="24" :lg="12">
                      <el-form-item label="省" prop="provinceCode">
                        <el-select v-model="streetServerType.provinceCode" @change="changeProvince" placeholder="请选择省"
                          class="wp100">
                          <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id+''">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="judgeStreetAdmin()" :sm="24" :md="24" :lg="12">
                      <el-form-item label="市" prop="cityCode">
                        <el-select v-model="streetServerType.cityCode" @change="changeCity" placeholder="请选择城市"
                          class="wp100">
                          <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id+''">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="judgeStreetAdmin()" :sm="24" :md="24" :lg="12">
                      <el-form-item label="区县" prop="districtCode">
                        <el-select v-model="streetServerType.districtCode" @change="changeDistrict" placeholder="请选择区县"
                          class="wp100">
                          <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id+''">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="judgeStreetAdmin()" :sm="24" :md="24" :lg="12">
                      <el-form-item label="街道" prop="streetCode">
                        <el-select v-model="streetServerType.streetCode" @change="changeStreet" placeholder="请选择街道"
                          class="wp100">
                          <el-option v-for="item in streetList" :key="item.streetCode" :label="item.streetName"
                            :value="item.streetCode"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="服务分类名称" prop="serverTypeName">
                        <el-input v-model="streetServerType.serverTypeName"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      streetServerType: {
        id: "",
        provinceCode: "",
        provinceName: "",
        cityCode: "",
        cityName: "",
        districtCode: "",
        districtName: "",

        streetCode: localStorage.getItem("streetCode"),
        streetName: localStorage.getItem("streetName"),
        //     streetCode: "",
        // streetName: "",
        streetRoadCode: localStorage.getItem("streetRoadCode"),
        createDt: "",
        serverTypeCode: "",
        serverTypeName: "",
        creator: localStorage.getItem("login_name"),
        creatorCode: localStorage.getItem("userCode"),
      },

      provinceList: [], //省列表
      cityList: [], //城市列表
      districtList: [], //区县列表
      streetList: [], //街道列表
    };
  },
  methods: {
    //获取省
    getProvinceList () {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.provinceList = response.data.data;
            var id = response.data.data[0].id;
            that.getCityList(id);
          }
        });
    },
    judgeStreetAdmin () {
      return localStorage.getItem("roleName") === "街道管理员" ? false : true;
    },
    //获取市
    getCityList (id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.cityList = response.data.data;
            var e = response.data.data[0].id;
            that.getDistrictList(e);
          }
        });
    },

    getDistrictList (id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.districtList = response.data.data;
            var e = response.data.data[0].id;
            that.getStreetList(e);
          }
        });
    },
    getStreetList (id) {
      let that = this;
      that.$http
        .post("/area/queryStreetList", { id: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.streetList = response.data.data.queryStreetList;
          }
        });
    },
    changeStreet (e) { },
    changeProvince (e) {
      this.handleClearCode(1)
      this.getCityList(e);
    },
    changeCity (e) {
      this.handleClearCode(2)
      this.getDistrictList(e);
    },
    changeDistrict (e) {
      this.handleClearCode(3)
      this.getStreetList(e);
    },

    handleClearCode (level) {
      //重新选择时，清空级联列表
      switch (level) {
        case 1:
          this.streetServerType.cityCode = ''
          this.streetServerType.districtCode = ''
          this.streetServerType.streetCode = ''
          this.streetServerType.communityCode = ''
          this.streetServerType.villageCode = ''
          break;
        case 2:
          this.streetServerType.districtCode = ''
          this.streetServerType.streetCode = ''
          this.streetServerType.communityCode = ''
          this.streetServerType.villageCode = ''
          break;
        case 3:
          this.streetServerType.streetCode = ''
          this.streetServerType.communityCode = ''
          this.streetServerType.villageCode = ''
          break;
        case 4:
          this.streetServerType.communityCode = ''
          this.streetServerType.villageCode = ''
          break;
        case 5:
          this.streetServerType.villageCode = ''
          break;
        default:
          break;
      }
    },




    goIndex () {
      this.$router.push({
        path: "/streetServerType",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm (formName) {
      var that = this;
      that.$http
        .post("/streetServerType/save", that.streetServerType)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$router.go(-1)
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo () {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/streetServerType/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.streetServerType = response.data.data;
              if (that.streetServerType.provinceCode) {
                that.getCityList(that.streetServerType.provinceCode)
              }
              if (that.streetServerType.cityCode) {
                that.getDistrictList(that.streetServerType.cityCode)
              }
              if (that.streetServerType.districtCode) {
                that.getStreetList(that.streetServerType.districtCode)
              }
            }
          });
      }
    },
    goAnchor (index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll () {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created () {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    //判断当前角色-admin显示省市区街道下拉框    街道管理员不显示省市区街道下拉框
    // if(localStorage.getItem('roleName') == '街道管理员'){
    //     //街道管理员
    //     this.streetCode = localStorage.getItem('streetCode');
    //     this.streetName = localStorage.getItem('streetName');
    // }

    this.getProvinceList();
  },
  mounted () {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
